import React, { useState, useEffect, useRef, createContext, useContext } from 'react'    
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useInterval, useMount } from 'react-use'
import styled, { css } from 'styled-components'
import ReactPlayer from 'react-player'
import moment from 'moment'
import { forEach } from 'lodash'

import { playtimes, resynctimes } from '../../data/times'
import { formatDate, msToTime, parseACF, convertTimecodetoMs } from '../../utils'
import { media, useBreakpoint, isClient } from '../../styles/utils'
import { container, padding, button } from '../../styles/global'
import { useVisibilityChange } from '../../utils/use-visibility-change'

const apiURL = 'https://worldtimeapi.org/api/timezone/Australia/Brisbane'

export const playerContext = createContext();

export const PlayerProvider = (props) => {
    const { Provider } = playerContext;
    const [status, setStatus] = useState()
    const [countdown, setCountdown] = useState()
    
    useMount(() => {
        setStatus('begin')
    })

	return (
		<Provider
			value={{
				status,
                setStatus,
                countdown,
                setCountdown
			}}
		>
            {props.children}
		</Provider>
	)
}


export const usePlayer = () => {

    //const data = parseACF(useStaticQuery(query), 'allWordpressInfopages', false)


    const audioRef = useRef(false)
    let latency = 0
    let seekTime = 0
    let offsetCurrentTime = 0
    let playtime_duration = 0
    let event_finished = false
    const hardOffset = 250 //ms  //this is applied on top of offset derived from server latency

    const [audioURL, setAudioURL] = useState(false)
    const [audioDuration, setAudioDuration] = useState(false)
    const [active, setActive] = useState(false)
    const [launched, setLaunched] = useState(false)
    const [eventFinished, setEventFinished] = useState(false)

    const [muted, setMuted] = useState(false)
    const [playingAudio, setPlayingAudio] = useState(false) 
    const [audioFileLoaded, setAudioFileLoaded] = useState(false)
    const [audioPercentLoaded, setAudioPercentLoaded] = useState(0) 
    const [audioProgress, setAudioProgress] = useState(0) 
    const [resyncAudio, setResyncAudio] = useState(false) 
    const [resyncing, setResyncing] = useState(false) 
    const [resyncCurrentTime, setResyncCurrentTime] = useState(0) 
    
    const [startTime, setStartTime] = useState(0) 
    const [startLatency, setStartLatency] = useState(0) 
    const [playerLatencyStart, setPlayerLatencyStart] = useState(0) 
    const [playerLatency, setPlayerLatency] = useState(0) 
    const [testingLatency, setTestingLatency] = useState(false) 
    const [testedLatencyBeforeNextPlay, setTestedLatencyBeforeNextPlay] = useState(false) 
    
    const [offset, setOffset] = useState(0) //ms
    const [updatedTime, setUpdatedTime] = useState(0)
    const [inputTime, setInputTime] = useState(0);

    const { status, setStatus, countdown, setCountdown } = useContext(playerContext)


    useMount(() => {

        //console.log('resynctimes',resynctimes);
        
        // need to call api twice to factor in initial connection start times
        fetch(apiURL).then( () => {
            getAPITime(false)
        }) 

        //console.log('data',data);


        // set intial input time

        let duration, audiofile
        for (let item of playtimes){


            duration = item.audioDuration
            audiofile = item.audiofile
            

            if (new Date().getTime() < (item.time.getTime() + parseInt(duration)) ) {
                setInputTime(item.time.getTime())
                setAudioURL(audiofile)
                setAudioDuration(duration)
                console.log('mount audiofile',audiofile);
                console.log('setInputTime formatted',item.time);
                break;
            }
        }

    })

    useEffect(() => {
        if (launched && !active) {
            setStatus('starting')
        }

        if (launched && active) {
            setStatus('playing')
        }

        if (launched && active && muted) {
            setStatus('muted')
        }
    }, [launched, active, muted])


    // determine whether user has come back from another tab
    useVisibilityChange({
        onShow: () => {
            console.log('onShow');
            setResyncAudio(true)
        }, 
        onHide: () => {
            
        }, 
    }) 


    // re sync audio periodically (every 2 mins)
    // useInterval(() => {
    //     // check whether current time is too close to playime to run api check
    //     if( (Date.now()+offset) < (inputTime - 10000) || (Date.now()+offset) > (inputTime + 3000)  ){
    //         console.log('2 minute resync to api');
    //         fetch(apiURL).then( () => {
    //             getAPITime(true)
    //         }) 
    //     } else {
    //         console.log('2 minute resync too close to playtime to run');
    //     }
    // }, !eventFinished ? 120000 : null)  //120000 //15000


    // loop engine
    useInterval(() => {

        const currentPosition = (audioDuration*audioProgress)
        //console.log('currentPosition',currentPosition);

        if(active && resyncing && currentPosition > resyncCurrentTime){
            setResyncing(false)
        }

        //check resync times
        if(active && !resyncing){
            for (let resynctime of resynctimes){
                if(currentPosition > (resynctime - 5000) && currentPosition < (resynctime - 5000 + 100)){

                    setResyncing(true)
                    setResyncCurrentTime(resynctime)

                    console.log('resync time - 5000', resynctime - 5000);
                    console.log('currentPosition', currentPosition);

                    fetch(apiURL).then( () => {
                        getAPITime(true)
                    }) 

                    break;
                }
            }
        }



        //set countdown
        //5 Days 2 Hours 29 Mins 01 Sec 
        let diffTime = inputTime - (Date.now()+offset);
        let duration = moment.duration(diffTime, 'milliseconds');
        setCountdown( duration.days() + " Days " + duration.hours() + " Hours " + duration.minutes() + " Mins " + duration.seconds() + " Sec " )

 

        // Check if there are no more playtimes 
        if (!event_finished) { //audioFileLoaded && 

            const pt = playtimes[playtimes.length-1]
            playtime_duration = pt.audioDuration
            


            if(Date.now() > (parseInt(pt.time.getTime()) + parseInt(playtime_duration)) ){ 
                event_finished = true
                setEventFinished(true)
                setStatus('finished')
                console.log('eventFinished');
            }
        }
       
        // start measuring player latency
        if(!active && audioFileLoaded && playingAudio && playerLatencyStart<1){
            setPlayerLatencyStart(performance.now())
        }

        // measure Player latency (launch button)
        if(!active && audioFileLoaded && audioProgress>0 && playerLatency<1){

            latency = performance.now() - playerLatencyStart
            console.log('latency',latency);
            // fix latency to 500ms if can't measure it properly
            if(latency>500) latency = 500
            setPlayerLatency(latency.toFixed(4))
            setTestedLatencyBeforeNextPlay(true)
        }


        // Open app if audio ready to play and latency measured
        if(!launched && !active && audioProgress>0 && playerLatency>0){

            setPlayingAudio(false)
            setMuted(false) 
            audioSeek(0)
            setLaunched(true)
            console.log('setLaunched');
        }

        if(launched && !event_finished){ 

            // set offset device time
            offsetCurrentTime = (Date.now()+offset)
            const t = formatDate(offsetCurrentTime)
            setUpdatedTime(t)


            //measure player latency again (if !active and 3 secs from next playtime)
            if(!active && !testingLatency && !testedLatencyBeforeNextPlay && (offsetCurrentTime > (inputTime - 3000)) ){

                console.log('measuring player latency again 3 secs out from next show');
                setPlayerLatency(0)
                setPlayerLatencyStart(performance.now())
                setTestingLatency(true)
                setMuted(true)
                setPlayingAudio(true)
                audioSeek(0)
            }

            // testing latency in between shows
            if(!active && setTestingLatency && audioProgress>0 && playerLatency<1){
                latency = performance.now() - playerLatencyStart
                console.log('latency',latency);
                // fix latency to 500ms if can't measure it properly
                if(latency>500) latency = 500
                setPlayerLatency(latency.toFixed(4))
                setPlayerLatencyStart(0)
                setTestingLatency(false)
                setTestedLatencyBeforeNextPlay(true)
                setPlayingAudio(false)
                
            }


             // measure Player latency at play time
            if(active && audioProgress>0 && startLatency<1){
                setStartLatency((performance.now() - startTime).toFixed(4))
            }

             // PLAY AUDIO
            // - play audio if Input time is less than device time 
            // - factor in latency from player
            // - factor in offset from server 
    
            if(!active && !playingAudio && !event_finished && ((inputTime-playerLatency) <= offsetCurrentTime)  ){ 

                // Seek Time - if already inside show time, set playhead position in seconds
                if(offsetCurrentTime > inputTime){
                    seekTime = (offsetCurrentTime - inputTime ) / 1000.
                }
                console.log('Date.now()',formatDate(Date.now()));
                console.log('offsetCurrentTime',formatDate(offsetCurrentTime));
                console.log('offset',offset);
                console.log('inputTime',formatDate(inputTime));
                console.log('playerLatency',playerLatency);
                console.log('seekTime',seekTime);

                setMuted(false)
                setPlayingAudio(true) 
                audioSeek(seekTime)
                setActive(true)
                setStartTime(performance.now())
            }

            // resync audio
            if(active && playingAudio && resyncAudio){

                console.log('resyncAudio');
                setResyncAudio(false)

                console.log('offsetCurrentTime',offsetCurrentTime);
                console.log('inputTime',inputTime);
                console.log('audioProgress',(audioDuration*audioProgress)/1000);
                
                const currentPlayhead = (audioDuration*audioProgress)/1000
                seekTime = (offsetCurrentTime - inputTime ) / 1000.
                const drift = seekTime - currentPlayhead

                console.log('currentPlayhead',currentPlayhead);
                console.log('seekTime',seekTime);
                console.log('drift',drift);

                if(drift > 0.25 || drift < -0.25){
                    
                    // reposition playhead
                    audioSeek(seekTime)
                    console.log('reposition playhead');
                }
            }
        }
 
    }, 50)
    
    const toggleMute = () => {
        setMuted(!muted)
    }
    
    const onLaunchButton = () => {
        setStatus('wait')
        setPlayingAudio(true)
        setMuted(true) 
    }

    const audioSeek = (sec) => {
        audioRef.current.seekTo(sec)
    }

    const onAudioDuration = (val) => {
        console.log('onAudioDuration',val);
        //setAudioDuration(val*1000)
    }

    const onAudioProgress = (val) => {
        //console.log('onAudioProgress',val);
        setAudioPercentLoaded(val.loaded)
        setAudioProgress(val.played)
    }
    
    const onAudioEnded = () => {
        console.log('onAudioEnded');

        setActive(false)
        setPlayingAudio(false)
        setTestedLatencyBeforeNextPlay(false)
        setStartLatency(0)

        // get next start time in playtimes
        const timeNow = Date.now()
        let audiofile, duration
        for (let item of playtimes){

            audiofile = item.audiofile
            duration = item.audioDuration
        


            if( timeNow < (item.time.getTime()) + parseInt(duration)){ // 
                const playtime = item.time.getTime()
                setInputTime(playtime)

                // console.log('timeNow',timeNow);
                // console.log('playtime',playtime);
                // console.log('timeNow-playtime',timeNow-playtime);
                // console.log('duration',duration);
                console.log('audiofile',audiofile);
                              
                setAudioURL(audiofile)
                setAudioDuration(duration)
                break;
            }    
        }

        console.log('no more playtimes');
  
    }

    const getAPITime = (resync) => {
  
        const requestTime = performance.now()

        fetch(apiURL)
            .then(response => {
                return response.json()
            })  
            .then(data => {
         
                let useDeviceTime = false

                 // use device time if error received from server 
                if(!data || data.error){
                    useDeviceTime = true
                    console.log('server error');
                }

                //use device time if server taking too long
                if( (performance.now() - requestTime) > 10000 ){
                    useDeviceTime = true
                    console.log('server taking too long');
                }
                          
                if(useDeviceTime){
                    
                    setOffset(0 + hardOffset)     
                    console.log('using Device Time');
                    console.log('offset',0 + hardOffset);      

                } else {
                    console.log('using API server time');
                    //processTime(requestTime,data,resync) 

                    let td = {}
                    td.now = Date.now()
                    td.requestTime = requestTime
                    td.resultTime = performance.now()     
                    td.ttfb = (td.resultTime - td.requestTime)
                    td.serverTime = new Date(data.utc_datetime).getTime()  //worldtimeapi    
                    td.normalisedServerTime = td.serverTime +(td.ttfb/2)
                    td.offset = parseFloat((td.normalisedServerTime - td.now).toFixed(2))
                   //console.log(td);
                    setOffset(td.offset + hardOffset)
                    console.log('td.offset',td.offset);
                    console.log('offset',td.offset  + hardOffset);
                }

                if(resync) setResyncAudio(true)
                
            })
    }



    const renderAudio = () => {
        if (status == 'finished') return;


        return (

            <Audio 
                ref={audioRef}
                url={audioURL} //audioURL //data.audio_file
                controls={true}
                muted={muted}
                playing={playingAudio}
                loop={false}
                volume={0.8}
                width={'0px'}
                height={'0px'}
                progressInterval={50}
                
                //onReady={ onAudioReady }
                onEnded={ onAudioEnded }                  
                onProgress={(e) => onAudioProgress(e)}
                onDuration={(e) => onAudioDuration(e)}

                // onPlay={()=>console.log('onPlay')}
                // onBuffer={(e)=>console.log('onBuffer',e)}
                // onBufferEnd={()=>console.log('onBufferEnd')}
                // onSeek={(e)=>console.log('onSeek', e)}

                config={{
                    file: {
                        attributes: {
                            preload: "auto",
                            onCanPlayThrough: () => {
                                setAudioFileLoaded(true)
                                console.log('onCanPlayThrough')
                            }
                        },
                    },
                }}
            />
    
        )
    }

    return [
        renderAudio,
        {
            play: onLaunchButton,
            launched: launched,
            playing: playingAudio,
            toggleMute
        }
    ]
}


// Shared


const Text = styled.div`
    color: ${props => props.color}; 
`

const Audio = styled(ReactPlayer)`
    visibility: hidden;
`

// Check if event active

export const isEventActive = () => {
    const now = new Date();

    let active = false;
    let duration = 1794142  //todo: make dynamic

    forEach(playtimes, (item, i) => {

        if (now > item.time.getTime() && now < (item.time.getTime() + duration)) {
            active = true
        }
    })

    return active
}



// export const query = graphql`
//     query {
//         allWordpressInfopages(filter: { slug: { eq: "home" } }) {
//             nodes {
//                 acf_json
//             }
//         }
//     }
// `
