const tempAudioPath = 'https://pda-assets.s3.ap-southeast-2.amazonaws.com/NIGHT_SKY_SOUNDTRACK.mp3'
export const audioDuration = 1794142.083
const now = new Date()

 // Test Play Times
 export const playtimes = [
    { 
        time: new Date(now.getTime() + 10000),
        audiofile: tempAudioPath,
        audioDuration: audioDuration
    },
 ]


// Actual Play Times

// export const playtimes = [

//      //sat 26 Sept
//      { 
//         time: new Date(2020, 8, 26, 19, 0, 0),
//         audiofile: tempAudioPath
//     },
//     { 
//         time:  new Date(2020, 8, 26, 19, 30, 0),
//         audiofile: tempAudioPath
//     },
//     { 
//         time: new Date(2020, 8, 26, 20, 0, 0),
//         audiofile: tempAudioPath
//     },
//     { 
//         time: new Date(2020, 8, 26, 20, 30, 0),
//         audiofile: tempAudioPath
//     },
// ]


// test times


// export const playtimes = [
//     { 
//         time: new Date(2020, 8, 26, 9, 16, 0),
//         audiofile: 2
//     },
//     { 
//         time: new Date(2020, 8, 26, 9, 46, 0),
//         audiofile: 1 
//     },
//     { 
//         time: new Date(2020, 8, 26, 10, 16, 0),
//         audiofile: 1
//     },
// ]


const resynctimecodes = [

    //test
    // { min:0, sec:20, frame:0 },
    // { min:0, sec:40, frame:0 },
    // { min:0, sec:60, frame:0 },

    //actual
    { min:0, sec:20, frame:0 },
    { min:5, sec:53, frame:2 },
    { min:8, sec:23, frame:18 },
    { min:11, sec:36, frame:0 },
    { min:22, sec:44, frame:14 },
    { min:24, sec:46, frame:10 },
    { min:25, sec:43, frame:13 },
]

export const resynctimes = resynctimecodes.map((item, i) => {
    return (item.min * 60000) + (item.sec * 1000) + ((item.frame / 25) * 1000); 
})
