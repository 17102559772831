import { css } from 'styled-components'
import { media } from './utils'

const type = {
    heading1: css`
        font-weight: 700;
        font-size: 44px;
        line-height: 1;

        ${media.phone`
            font-size: 34px;
        `}
    `,
    heading2: css`
        font-weight: 700;
        font-size: 19px;
        line-height: 1;
    `,
    body: css`
        font-weight: 700;
        font-size: 24px;
        line-height: 1.34;

        ${media.phone`
            font-size: 15px;
        `}
    `,
    caption: css`
        font-weight: 700;
        font-size: 12px;
        line-height: 1;
        letter-spacing: -0.005em;
    `
}

export default type